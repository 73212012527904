import React from 'react'
import Layout from '../components/Layout'
import AboutHeader from '../components/about/AboutHeader'
import ColumnParagraphs from '../components/about/ColumnParagraphs'
import AboutSloganBanner from '../components/about/AboutSloganBanner'

const AboutPage = () => {
  const useCopy = {
    vision: {
      de: {
        titleSmall: 'unsere',
        titleBig: 'vision',
        paragraphs: [
          'Der <b>Bizeps</b> steht für Training, Bewegung und Belastbarkeit.',
          '<b>Medizin</b> steht für die Wiederherstellung der Gesundheit.',
          'Wir sind davon überzeugt, dass Krafttraining und regelmäßige Bewegung die Lösung für viele gesundheitliche Probleme darstellt. Sozusagen die Bizepsmedizin. Hier ergreifen WIR die <b>Initiative.</b>',
          'Um DIR mit unserer Expertise und Erfahrung in den Bereichen Fitness und Physiotherapie zur Seite zu stehen.'
        ]
      },
      en: {
        titleSmall: 'our',
        titleBig: 'vision',
        paragraphs: [
          '<b>Biceps</b> stands for training, movement and resilience.',
          '<b>Medicine</b> stands for the restoration of health.',
          'We are convinced that strength training and regular exercise is the solution to many health problems. So to speak: Biceps medicine.',
          'With our expertise and experience, WE are taking the <b>initiative</b> to guide you in the world of fitness and physical therapy.'
        ]
      },
    },
    motivation: {
      de: {
        titleSmall: 'unsere',
        titleBig: 'motivation',
        paragraphs: [
          'Nie zuvor sind Informationen so einfach verfügbar gewesen wie heute. Segen und Fluch zugleich.',
          'Sex sells, nicht nur auf unserer Website. In unserer Gesellschaft werden Emotionen und falsche Erwartungshaltungen ausgenutzt um Profit zu machen. Social Media ist ein Dschungel. Wer am lautesten brüllt bekommt die meiste Aufmerksamkeit.',
          'Grundsätzlich kein Problem, wenn es um Gesundheit geht, jedoch moralisch fragwürdig.',
          'Bei der Behandlung von Schmerzen verursachen veraltete Therapiemaßnahmen oft Nocebos und Chronifizierungen. Gesundheitsmythen wie zum Beispiel, das man immer gerade sitzen muss, können dazu beitragen, dass ein Krankheitsbild sich verschlechtert. Ja du hast richtig gehört. Du musst keinen perfekt geraden Rücken haben.',
          'Wir sind davon überzeugt, dass sich Fitnesstraining und Physiotherapie optimal ergänzen können und freuen uns dich auf deinem Weg zu deinem leistungsfähigeren, unabhängigeren Selbst zu begleiten.'
        ]
      },
      en: {
        titleSmall: 'our',
        titleBig: 'motivation',
        paragraphs: [
          'Never before has information been as readily available as it is today - a blessing and a curse at the same time.',
          'Sex sells, not only on our website. In our society, emotions and false expectations are exploited to make profit. Social media is a jungle. Whoever roars the loudest gets the most attention.',
          "In principle, not a problem. If it's about health however, morally questionable.",
          "When it comes to treating pain, outdated therapies often cause nocebos and chronification. Health myths such as always sitting up straight can contribute to worsening a medical condition. Yes you read right. You don't have to have a perfectly straight back.",
          'We believe that fitness training and physical therapy can complement each other perfectly and are looking forward to guide you on your path to a better performing, more independent you.'
        ]
      },
    }
  }
  return (
    <Layout pageTitle="BMI | About">
      <AboutHeader lang />
      <ColumnParagraphs  lang copy={useCopy.vision} />
      <ColumnParagraphs  lang copy={useCopy.motivation} />
      <AboutSloganBanner lang />
    </Layout>
  )
}

export default AboutPage