import React from 'react'
import { Link } from 'gatsby'
import background from '../../images/concrete-bg.jpg'
import image from '../../images/aboutfooter.jpg'
import '../../styles/components/aboutsloganbanner.css'

const AboutSloganBanner = ({ lang }) => {
  const useCopy = {
    de: {
      slogan: ['fakten', 'fitness', 'physiotherapie'],
      copy: 'Im Endeffekt geht es bei beiden Interventionen darum, den Körper durch einen Reiz aus dem Gleichgewicht zu bringen, damit dieser mit einer Anpassung und somit Verbesserung des Ist-Zustandes antwortet. Wo in der Physiotherapie häufig auf eine bestimmte verletzte Struktur eingegangen wird, steht im Fitnesstraining die Optimierung des individuellen Leistungszustandes (bzw. optischen Erscheinungsbildes) im Fokus. Bei beiden Interventionen sollte immer eine ganzheitliche Betrachtungsweise des Menschen und seiner*ihrer Ziele im Vordergrund stehen.',
      cta: 'Buche einen Vortrag',
      buttonCopy: 'Erfahre Mehr'
    },
    en: {
      slogan: ['facts', 'fitness', 'physiotherapy'],
      copy: `Bottom line is that both interventions involve using a stimulus to bring the body out of homeostasis, therefore forcing adaptation and thus improving its current state. Where physiotherapy often focuses on a specific injured structure, fitness training focuses on optimizing the individual's performance (or visual appearance).
      In both interventions, the focus should always be on a holistic view of the individual and his/her goals.`,
      cta: 'Book a workshop',
      buttonCopy: 'Learn More'
    }
  }
  return (
    <div className="slogan-banner-container">
      <div className="slogan-image" style={{ backgroundImage: `url(${image})`}}></div>
      <div className="bg-concrete slogan-text" style={{ backgroundImage: `url(${background})`}}>
        <div className="slogan-container">
          <div className="slogan-white">
            {useCopy[lang].slogan.map((s, i) => (
              <span key={i}>{s}</span>
            ))}
          </div>
          <div className="slogan-grey">
            {useCopy[lang].slogan.map((s, j) => (
              <span key={j}>{s}</span>
            ))}
          </div>
        </div>
        <p className="slogan-copy">{useCopy[lang].copy}</p>
        <div className="cta-container">
          <span>{useCopy[lang].cta}</span>``
            <button aria-label="Book Workshop"><Link to='/workshop'><span>{useCopy[lang].buttonCopy} →</span></Link></button>
        </div>
      </div>
    </div>
  )
}

export default AboutSloganBanner