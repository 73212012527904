import React from 'react'
import parse from 'html-react-parser';
import '../../styles/components/columnparagraphs.css'

const ColumnParagraphs = ({ lang, copy }) => {
  const paddingStyle = copy[lang].titleBig === 'vision' ? { 'paddingTop': '56px', 'paddingBottom': '12px' } : { 'paddingTop': '12px', 'paddingBottom': '56px' };
  return (
    <div className="column-container" style={paddingStyle}>
      <div className="column-title">
        <p>{copy[lang].titleSmall}</p>
        <p>{copy[lang].titleBig}</p>
      </div>
      <div className="column-paragraphs">
        {copy[lang].paragraphs.map((p, i) => (
          <p key={i}>{parse(p)}</p>
        ))}
      </div>
    </div>
  )
}

export default ColumnParagraphs