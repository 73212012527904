import React from 'react'
import '../../styles/components/aboutheader.css'
import background from '../../images/aboutbanner.jpg'

const AboutHeader = ({ lang }) => {
  const headerCopy = {
    de: ['Das ist die ', 'Bizeps Medizin Initiative'],
    en: ['This is the ', 'Bizeps Medizin Initiative']
  }
  return (
    <>
      <div className="about-header-banner" style={{ backgroundImage: `url(${background})`}}>
        <div className="overlay-grey"></div>
        <div className="about-header-copy">
          <div><span>{headerCopy[lang][0]}</span><span>{headerCopy[lang][1]}</span></div>
        </div>
      </div>
    </>
  )
}

export default AboutHeader